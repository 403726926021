import axiosInstance from '@/plugins/axios'

export default {
  getStatistic(force = false) {
    const url = 'admin/statistics'

    return axiosInstance.get(url, { params: { force } })
      .then(response => response.data)
  },

  getOrders(accountTypes, platforms, force = false) {
    const url = 'admin/statistics/orders'

    return axiosInstance.get(url, { params: { filters: { accountTypes, platforms, force } } })
      .then(response => response.data)
  },

  getFundingRate(accountTypes, platforms, force = false) {
    const url = 'admin/statistics/funding-rate'

    return axiosInstance.get(url, { params: { filters: { accountTypes, platforms, force } } })
      .then(response => response.data)
  },

  get2PhaseAnalysis(accountTypes, platforms, force) {
    const url = 'admin/statistics/2phase-analysis'

    return axiosInstance.get(url, { params: { filters: { accountTypes, platforms, force } } })
      .then(response => response.data)
  },

  getFundedAnalysis(accountTypes, platforms) {
    const url = 'admin/statistics/funded-analysis'

    return axiosInstance.get(url, { params: { filters: { accountTypes, platforms } } })
      .then(response => response.data)
  },

  getStatisticReport(from, to) {
    const url = 'admin/statistics-report'

    return axiosInstance.get(url, { params: { filters: { from, to } } })
      .then(response => response.data)
  },

  getRegisteredUsersByCountry(from, to, useAffKeys) {
    const url = 'admin/marketing-statistics/users-by-country'

    return axiosInstance.get(url, { params: { filters: { from, to, use_aff_keys: useAffKeys } } })
      .then(response => response.data)
  },

  getWeeklyRegistrations() {
    const url = 'admin/marketing-statistics/weekly-registrations'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getWithdrawalStatistic(force = false) {
    const url = 'admin/withdrawal-statistics'

    return axiosInstance.get(url, { params: { force } })
      .then(response => response.data)
  },

  getSalesByCountry(countryId) {
    const url = `admin/withdrawal-statistics/sales-by-country/${countryId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getFundedProfitsStatisticsByTags(tags) {
    const url = 'admin/funded-statistics/active-profit'

    return axiosInstance.post(url, { tags })
      .then(response => response.data)
  },

  getSpendVsWithdrawByCountry(from, to) {
    const url = 'admin/fin-statistics/spend-vs-withdraw'

    return axiosInstance.get(url, { params: { filters: { from, to } } })
      .then(response => response.data)
  },

  getPassRatesByCountry(from, to, country) {
    const url = 'admin/fin-statistics/pass-rates'

    return axiosInstance.get(url, { params: { filters: { from, to, country } } })
      .then(response => response.data)
  },

  getTopFunded(form) {
    const url = 'admin/fin-statistics/top-funded'

    return axiosInstance.get(url, {
      params: {
        filters: form,
      },
    })
      .then(response => response.data)
  },

  getWithdrawals(accountTypes, from, to, country) {
    const url = 'admin/fin-statistics/withdrawals'

    return axiosInstance.get(url, {
      params: {
        filters: {
          accountTypes, from, to, country,
        },
      },
    })
      .then(response => response.data)
  },

  getSales(from, to, country, force) {
    const url = 'admin/fin-statistics/sales'

    return axiosInstance.get(url, {
      params: {
        filters: {
          from, to, country, force,
        },
      },
    })
      .then(response => response.data)
  },

  getWithdrawalsByAccTypes(from, to, country, byCreatedAt, force) {
    const url = 'admin/fin-statistics/withdrawals-by-type'

    return axiosInstance.get(url, {
      params: {
        filters: {
          from, to, country, byCreatedAt, force,
        },
      },
    })
      .then(response => response.data)
  },

  getDiscountUsage(from = null, to = null) {
    const url = 'admin/affiliate-statistics/discounts'

    return axiosInstance.get(url, {
      params: {
        filters: {
          from, to,
        },
      },
    })
      .then(response => response.data)
  },

  getTopAffiliates(from = null, to = null) {
    const url = 'admin/affiliate-statistics/affiliates'

    return axiosInstance.get(url, {
      params: {
        filters: {
          from, to,
        },
      },
    })
      .then(response => response.data)
  },

  getOrdersDataByAffAndDiscount(discount = null, affiliate = null) {
    const url = 'admin/affiliate-statistics/sales'

    return axiosInstance.get(url, {
      params: {
        filters: {
          discount, affiliate,
        },
      },
    })
      .then(response => response.data)
  },

  getPartnerMonthlyPayouts(partnerId, forceReload) {
    const url = 'admin/partner-statistics/payouts'

    return axiosInstance.get(url, {
      params: {
        filters: {
          id: partnerId,
          forceReload,
        },
      },
    })
      .then(response => response.data)
  },
}
